import { Box } from '@chakra-ui/react';

export const spaceHeight = { base: '40px', lg: '64px' };
export const bigSpaceHeight = { base: '80px', lg: '120px' };

export function Space() {
  return <Box h={spaceHeight} />;
}

export function BigSpace() {
  return <Box h={bigSpaceHeight} />;
}
