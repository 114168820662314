import { createClient } from '@sanity/client';

import { publicEnv } from '~/features/environment/public';

export const projectId = publicEnv.NEXT_PUBLIC_SANITY_PROJECT_ID;
export const dataset = publicEnv.NEXT_PUBLIC_SANITY_DATASET;
export const apiVersion = '2022-11-15';
export const sanityGraphqlUrl = `https://${projectId}.apicdn.sanity.io/v1/graphql/${dataset}/default`;

if (publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME === 'development') {
  console.info(`graphql url: ${sanityGraphqlUrl}`);
}

export const sanityClient = createClient({
  projectId,
  dataset,
  useCdn: true,
  apiVersion: '2023-05-03',
});
