import Image, { ImageProps } from 'next/image';
import { useNextSanityImage } from 'next-sanity-image';

import { SanityImageType as SanityImageType } from '~/features/page-template/models/image';
import { sanityClient } from '~/libs/sanity';

export function SanityImage({
  image,
  quality = 100,
  placeholder = 'blur',
  priority = false,
  fill = false,
  alt = '',
  ...extraImageProps
}: { image: SanityImageType; alt?: string } & Omit<ImageProps, 'src' | 'alt'>) {
  const imageProps = useNextSanityImage(sanityClient, image);
  return (
    <Image
      alt={image.asset.altText ?? alt}
      blurDataURL={image.asset.metadata.lqip}
      fill={fill}
      height={fill ? undefined : imageProps.height}
      loader={imageProps.loader}
      loading={priority ? 'eager' : 'lazy'}
      placeholder={placeholder}
      priority={priority}
      quality={quality}
      src={imageProps.src}
      width={fill ? undefined : imageProps.width}
      {...extraImageProps}
    />
  );
}
